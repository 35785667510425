import React, { useRef, useContext } from "react"
import {
  CartCtx,
  CustomerCtx,
  AppCtx,
  useActions,
  useContextState,
} from "@components/contexted"

import {
  NoItemsInCart,
  BeforeCustomerData,
  BeforePayment,
} from "@components/ecommerce"

const Checkout = (props) => {
  const cart = useContext(CartCtx)
  const customer = useContext(CustomerCtx)
  const { items, orderInApi } = useContextState(CartCtx, [
    "items",
    "orderInApi",
  ])
  const { setShipping, setOrder, onError, setCoupon } = useActions(CartCtx, [
    "setShipping",
    "setOrder",
    "onError",
    "setCoupon",
  ])
  const { changeValue } = useContextState(CustomerCtx, ["changeValue"])

  if (items.length === 0) {
    return <NoItemsInCart cart={cart} {...props} />
  }

  if (orderInApi.id) {
    return <BeforePayment cart={cart} {...props} />
  }

  // te akcje i konteksty powinny być wyciągane w Before customer data, a tutaj tylko context
  return (
    <BeforeCustomerData
      pageContext={props.pageContext}
      cart={cart.state}
      customer={customer.state}
      actions={{
        changeValue: changeValue,
        initLoading: props.initLoading,
        setShipping: setShipping,
        setOrder: setOrder,
        onError: onError,
        setCoupon: setCoupon,
      }}
    />
  )
}

export default Checkout
